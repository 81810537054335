import React from 'react';
import ProjectManagement from '../components/ProjectManagement';
import { Container } from '@mui/material';

export default function Report() {
  return (
    <>
      <Container maxWidth='lg'>{/* <ProjectManagement /> */}</Container>
    </>
  );
}
