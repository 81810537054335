import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import ClearIcon from '@mui/icons-material/Clear';

export default function ActualCostItem({
  index,
  detail,
  costs,
  remainingCosts,
  onCostSelect,
  updateTotalAmount = () => {},
  onChange = () => {},
  onRemove = () => {},
}) {
  // Hàm để tính tổng tiền
  // debugger;
  const [amount, setAmount] = useState(detail?.amount || '');
  const [error, setError] = useState('Vui lòng không bỏ trống');

  useEffect(() => {
    if (detail.cost && detail.amount) {
      setError('');
    } else {
      setError('Vui lòng không bỏ trống');
    }
  }, [detail]);
  useEffect(() => {
    updateTotalAmount();
  }, [amount]);
  const handleInputChange = (id, key, value) => {
    // debugger;
    if (key === 'amount') {
      setAmount(value);
    }
    onChange({
      ...detail,
      [key]: value,
    });
  };

  const handleSelectCost = async (event, value) => {
    // debugger;
    const cost = value;
    const selectedCost = costs.find((el) => el.name === cost);
    const validateCost = onCostSelect(selectedCost);

    if (validateCost) {
      onChange({
        ...detail,
        cost,
      });
    }
  };

  const deleteDiv = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Bạn chắc chắn muốn xóa? ',
        text: 'Hạng mục này sẽ bị xóa vĩnh viễn!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Xóa hạng mục',
        cancelButtonText: 'Hủy bỏ',
      });
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Đã xóa!',
          text: 'Hạng mục đã được xóa thành công.',
          icon: 'success',
        });
        onRemove(detail);
      }
    } catch (error) {}
  };

  return (
    <>
      <tr>
        <td
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
            // width: '5%',
          }}
        >
          {index + 1}
        </td>
        <td>
          <Autocomplete
            size='small'
            disablePortal
            id='combo-box-demo'
            options={remainingCosts?.map((option) => option.name)}
            defaultValue={detail?.cost}
            disabled={detail?.cost ? true : false}
            onChange={handleSelectCost}
            renderInput={(params) => (
              <TextField {...params} placeholder='Tên chi phí' />
            )}
          />
          <span className='text-danger'>{error}</span>
        </td>
        <td>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TextField
              placeholder='Thành tiền'
              id='outlined-size-small'
              value={amount}
              sx={{ width: '100%' }}
              size='small'
              type='number'
              onChange={(e) =>
                handleInputChange(detail.id, 'amount', e.target.value)
              }
            />

            <button
              className='btn btn-danger ms-2'
              style={{
                width: '32px',
                height: '32px',
                padding: '0',
              }}
              onClick={() => deleteDiv(detail.id)}
              type='button'
            >
              <ClearIcon sx={{ fontSize: '20px', fontWeight: 'bold' }} />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
}
