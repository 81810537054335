import { useEffect, useId, useRef, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Tab,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import toast, { Toaster } from 'react-hot-toast';

// API
import {
  checkCodeAPI,
  saveEmployeeAPI,
  selectEmployeeAPI,
} from '../../../../apis/employeeAPI';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../home/components/Loading/Loading';
import NavigationButton from '../../../common/NavigationButton';

export default function EditEmployee() {
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const params = useParams();
  const id = params.id;

  const [item, setItem] = useState('1');
  const handleChangeItem = (evt, newValue) => {
    setItem(newValue);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [errorCode, setErrorCode] = useState('');
  const [healthCer, setHealthCer] = useState('');
  const getEmployeeById = async () => {
    try {
      let data = await selectEmployeeAPI(id);
      console.log('🚀 ~ getEmployeeById ~ data:', data);

      setValue(data);
      setIsLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    getEmployeeById();
  }, []);

  // Thông số chung
  const handleChangeInput = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  const handleCheckCode = async () => {
    if (value.code) {
      const res = await checkCodeAPI(value.code);
      if (!res) {
        setErrorCode('Mã nhân sự đã tồn tại');
        return;
      }
      setErrorCode('');
    }
  };

  const handleFileChange = (e) => {
    debugger;
    setValue({ ...value, [e.target.name]: e.target.files[0] });
    setHealthCer(e.target.files[0].name);
  };
  console.log(value);

  // Thêm nhân sự
  const handleSaveEmployee = async (e) => {
    e.preventDefault();
    console.log(value);
    if (!value.name) {
      toast.error('Vui lòng nhập tên nhân sự');
    } else if (!value.code) {
      toast.error('Vui lòng nhập mã nhân sự');
    } else if (errorCode !== '') {
      toast.error('Vui lòng kiểm tra lại');
    } else {
      setIsLoading(true);
      try {
        setErrorCode('');

        const data = await saveEmployeeAPI(value);
        if (data) {
          setIsLoading(false);
          toast.success('Cập nhật nhân sự thành công');
          // navigate('/catalogue/employees');
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        toast.error('Cập nhật nhân sự thất bại');
      }
    }
  };

  return (
    <>
      <Toaster position='top-right' />

      {isLoading ? (
        <Loading />
      ) : (
        <div className='container mt-2 mb-5' style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: '20px', left: '80px' }}>
            <NavigationButton
              url='/catalogue/employees'
              name='Danh sách nhân sự'
            />
          </div>
          <div>
            <h1 className='text-center pt-3'>CẬP NHẬT NHÂN SỰ</h1>
            <form noValidate onSubmit={handleSaveEmployee}>
              <Container>
                <TabContext value={item}>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <TabList onChange={handleChangeItem}>
                      <Tab
                        sx={{ fontWeight: 'bold' }}
                        label='THÔNG TIN CHUNG'
                        value='1'
                      />
                      <Tab
                        sx={{ fontWeight: 'bold' }}
                        label='HỒ SƠ NĂNG LỰC'
                        value='2'
                      />
                    </TabList>
                  </Box>
                  {/* Thông số chung */}
                  <TabPanel value='1'>
                    <div className='ps-5 pe-5' style={{ marginLeft: '150px' }}>
                      {/* TÊN NHÂN SỰ */}
                      <div className='form-group d-flex mb-3'>
                        <label
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            width: '170px',
                            marginRight: '20px',
                          }}
                          className='form-control'
                          htmlFor='name'
                        >
                          Tên nhân sự{' '}
                          <span className='text-danger fw-bold'>*</span>
                        </label>
                        <input
                          id='name'
                          name='name'
                          // ref={nameRef}
                          value={value?.name}
                          className=' form-control w-50'
                          type='text'
                          placeholder='Nhập tên nhân sự...'
                          onChange={handleChangeInput}
                        />
                      </div>
                      {/* MÃ NHÂN SỰ */}
                      <div
                        style={{ alignItems: 'center' }}
                        className='form-group d-flex mb-3'
                      >
                        <label
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            width: '170px',
                            marginRight: '20px',
                          }}
                          className='form-control'
                          htmlFor='code'
                        >
                          Mã nhân sự{' '}
                          <span className='text-danger fw-bold'>*</span>
                        </label>
                        <input
                          id='code'
                          name='code'
                          value={value?.code}
                          className=' form-control w-50 me-2'
                          type='text'
                          placeholder='Nhập mã nhân sự...'
                          onChange={handleChangeInput}
                          onBlur={handleCheckCode}
                        />
                        <span className='text-danger '>{errorCode}</span>
                      </div>
                      {/* PHÒNG BAN */}
                      <div className='form-group d-flex mb-3'>
                        <label
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            width: '170px',
                            marginRight: '20px',
                          }}
                          className='form-control'
                          htmlFor='department'
                        >
                          Phòng ban{' '}
                          <span className='text-danger fw-bold'>*</span>
                        </label>
                        <input
                          id='department'
                          name='department'
                          value={value?.department}
                          className=' form-control w-50'
                          type='text'
                          placeholder='Nhập phòng ban...'
                          onChange={handleChangeInput}
                        />
                      </div>
                      {/* CHỨC VỤ*/}
                      <div className='form-group d-flex mb-3'>
                        <label
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            width: '170px',
                            marginRight: '20px',
                          }}
                          className='form-control'
                          htmlFor='position'
                        >
                          Chức vụ <span className='text-danger fw-bold'>*</span>
                        </label>
                        <input
                          id='position'
                          name='position'
                          value={value?.position}
                          className=' form-control w-50'
                          type='text'
                          placeholder='Nhập chức vụ...'
                          onChange={handleChangeInput}
                        />
                      </div>

                      {/* HÌNH THỨC*/}
                      <div className='form-group d-flex mb-3'>
                        <label
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            width: '170px',
                            marginRight: '20px',
                          }}
                          className='form-control'
                        >
                          Hình thức{' '}
                          <span className='text-danger fw-bold'>*</span>
                        </label>
                        <FormControl>
                          <InputLabel size='small' sx={{}}>
                            Chọn hình thức hợp đồng
                          </InputLabel>

                          <Select
                            size='small'
                            value={value?.method}
                            defaultValue={value?.method}
                            label='Chọn hình thức hợp đồng'
                            name='method'
                            onChange={handleChangeInput}
                            sx={{ width: '250px' }}
                          >
                            <MenuItem
                              sx={{ height: '40px' }}
                              value='Kí hợp đồng'
                            >
                              Kí hợp đồng
                            </MenuItem>
                            <MenuItem value='Thử việc'>Thử việc</MenuItem>
                            <MenuItem value='Nghỉ không lương'>
                              Nghỉ không lương
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {/* ĐỊA CHỈ*/}
                      <div className='form-group d-flex'>
                        <label
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            width: '170px',
                            marginRight: '20px',
                          }}
                          className='form-control'
                          htmlFor='contact'
                        >
                          Địa chỉ <span className='text-danger fw-bold'>*</span>
                        </label>
                        <textarea
                          id='contact'
                          name='contact'
                          value={value?.contact}
                          className=' form-control w-50'
                          type='text'
                          placeholder='Nhập địa chỉ...'
                          onChange={handleChangeInput}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  {/* HỒ SƠ NĂNG LỰC */}
                  <TabPanel value='2'>
                    <div className='ps-5 pe-5'>
                      {/* CĂN CƯỚC CÔNG DÂN*/}
                      {/* <div
                        className='d-flex mb-3'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          gap: '20px',
                        }}
                      >
                        <label
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            width: '170px',
                          }}
                          className='form-control'
                          htmlFor='IDCard'
                        >
                          Căn cước công dân{' '}
                          <span className='text-danger fw-bold'>*</span>
                        </label>
                        <div
                          style={{
                            width: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <input
                            className=' form-control w-75'
                            readOnly
                            value={
                              value.IDCard?.name
                                ? value.IDCard?.name
                                : value.nameFileID !== 'undefined'
                                ? value.nameFileID
                                : ''
                            }
                            title={
                              value.IDCard?.name
                                ? value.IDCard?.name
                                : value.nameFileID !== 'undefined'
                                ? value.nameFileID
                                : ''
                            }
                            placeholder='Vui lòng chọn tài liệu'
                          />
                          <input
                            type='file'
                            style={{ width: '110px' }}
                            className='custom-file-input '
                            onChange={handleFileChange}
                            name='IDCard'
                          />
                        </div>
                      </div> */}
                      {/* BẰNG CẤP - CHỨNG CHỈ*/}
                      <div
                        className='d-flex mb-3'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          gap: '20px',
                        }}
                      >
                        <label
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            width: '170px',
                          }}
                          className='form-control'
                          htmlFor='degree'
                        >
                          Bằng cấp - chứng chỉ{' '}
                          <span className='text-danger fw-bold'>*</span>
                        </label>
                        <div
                          style={{
                            width: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <input
                            className=' form-control w-75'
                            readOnly
                            value={
                              value.degree?.name
                                ? value.degree?.name
                                : value.nameFileDegree !== 'undefined'
                                ? value.nameFileDegree
                                : ''
                            }
                            title={
                              value.degree?.name
                                ? value.degree?.name
                                : value.nameFileDegree !== 'undefined'
                                ? value.nameFileDegree
                                : ''
                            }
                            placeholder='Vui lòng chọn tài liệu'
                          />
                          <input
                            type='file'
                            style={{ width: '110px' }}
                            className='custom-file-input '
                            onChange={handleFileChange}
                            name='degree'
                          />
                        </div>
                      </div>
                      {/* Thẻ an toàn lao động*/}
                      <div
                        className='d-flex mb-3'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          gap: '20px',
                        }}
                      >
                        <label
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            width: '170px',
                          }}
                          className='form-control'
                          htmlFor='safetyCard'
                        >
                          Thẻ an toàn lao động{' '}
                          <span className='text-danger fw-bold'>*</span>
                        </label>
                        <div
                          style={{
                            width: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <input
                            className=' form-control w-75'
                            readOnly
                            value={
                              value.safetyCard?.name
                                ? value.safetyCard?.name
                                : value.nameFileSafetyCard !== 'undefined'
                                ? value.nameFileSafetyCard
                                : ''
                            }
                            title={
                              value.safetyCard?.name
                                ? value.safetyCard?.name
                                : value.nameFileSafetyCard !== 'undefined'
                                ? value.nameFileSafetyCard
                                : ''
                            }
                            placeholder='Vui lòng chọn tài liệu'
                          />
                          <input
                            type='file'
                            style={{ width: '110px' }}
                            className='custom-file-input '
                            onChange={handleFileChange}
                            name='safetyCard'
                          />
                        </div>
                      </div>
                      {/* Hợp đồng lao động*/}
                      {/* <div
                        className='d-flex mb-3'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          gap: '20px',
                        }}
                      >
                        <label
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            width: '170px',
                          }}
                          className='form-control'
                          htmlFor='contract'
                        >
                          Hợp đồng lao động{' '}
                          <span className='text-danger fw-bold'>*</span>
                        </label>
                        <div
                          style={{
                            width: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <input
                            className=' form-control w-75'
                            readOnly
                            value={
                              value.contract?.name
                                ? value.contract?.name
                                : value.nameFileContract !== 'undefined'
                                ? value.nameFileContract
                                : ''
                            }
                            title={
                              value.contract?.name || value.nameFileContract
                            }
                            placeholder='Vui lòng chọn tài liệu'
                          />
                          <input
                            type='file'
                            style={{ width: '110px' }}
                            className='custom-file-input '
                            onChange={handleFileChange}
                            name='contract'
                          />
                        </div>
                      </div> */}
                      {/* Giấy khám sức khỏe*/}
                      <div
                        className='d-flex mb-3'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          gap: '20px',
                        }}
                      >
                        <label
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            width: '170px',
                          }}
                          className='form-control'
                          htmlFor='healthCer'
                        >
                          Giấy khám sức khỏe{' '}
                          <span className='text-danger fw-bold'>*</span>
                        </label>

                        <div
                          style={{
                            width: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <input
                            className=' form-control w-75'
                            readOnly
                            value={
                              value.healthCer?.name
                                ? value.healthCer?.name
                                : value.nameFileHealthCer !== 'undefined'
                                ? value.nameFileHealthCer
                                : ''
                            }
                            title={
                              value.healthCer?.name
                                ? value.healthCer?.name
                                : value.nameFileHealthCer !== 'undefined'
                                ? value.nameFileHealthCer
                                : ''
                            }
                            placeholder='Vui lòng chọn tài liệu'
                          />
                          <input
                            type='file'
                            style={{ width: '110px' }}
                            className='custom-file-input '
                            onChange={handleFileChange}
                            name='healthCer'
                          />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabContext>
              </Container>
              {/* SUBMIT */}
              <div
                style={{
                  marginTop: '20px',
                  marginRight: '20px',
                  textAlign: 'end',
                }}
              >
                <button
                  style={{
                    width: '45px',
                    height: '45px',
                    padding: 0,
                  }}
                  className='btn btn-outline-success'
                  type='submit'
                >
                  <SaveIcon
                    sx={{
                      fontSize: '25px',
                      fontWeight: 'bold',
                    }}
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
