import React, { useEffect, useState } from 'react';
import { Container, Link } from '@mui/material';
import {
  saveActualQuantityAndRevenueAPI,
  getNextMondayAPI,
  getOldQuantityRevenueAPI,
  selectProjectAPI,
} from '../../../apis/reportAPI';
import { useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { QuantityRevenuePerWeek } from './QuantityRevenuePerWeek';
import Loading from '../../home/components/Loading/Loading';
import GoToTop from '../../home/components/GoToTop/GoToTop';
import NavigationButton from '../../common/NavigationButton';

export default function QuantityRevenues() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const params = useParams();
  const [project, setProject] = useState();
  const [quantityRevenues, setQuantityRevenues] = useState(); //set = API
  const [errorGetMonday, setErrorGetMonday] = useState(false);
  const [actualWeek, setActualWeek] = useState('');
  const idProject = params.code;
  const getNextModay = async (actualWeek, idProject) => {
    // debugger;
    try {
      const nextMonday = await getNextMondayAPI(actualWeek, idProject);
      setActualWeek(nextMonday);
    } catch (error) {
      setErrorGetMonday(true);
      console.error('Error fetching actualWeek:', error);
    }
  };
  const getProjects = async (idProject) => {
    // debugger;
    try {
      const data = await selectProjectAPI(idProject);
      setProject(data);
      getNextModay(0, idProject);
      return data;
    } catch (error) {
      console.error('Error fetching project:', error);
    }
  };
  const getOldQuantityRevenues = async (idProject) => {
    // debugger;
    try {
      const oldQuantityRevenues = await getOldQuantityRevenueAPI(idProject);
      // console.log(oldQuantityRevenues);
      const tempQuantityRevenues = oldQuantityRevenues?.map(
        (quantityRevenue) => (
          <QuantityRevenuePerWeek
            idQuantityRevenue={quantityRevenue.id}
            week={quantityRevenue.week}
            fromDateToDate={quantityRevenue.fromDateToDate}
            actualQuantityAndRevenueDetails={
              quantityRevenue.actualQuantityAndRevenueDetails
            }
            key={quantityRevenue.id}
            onValueChange={handleChildValueChange}
          />
        )
      );
      if (oldQuantityRevenues.length !== 0) {
        const actualWeek = oldQuantityRevenues?.map(
          (oldQuantityRevenuePerWeek) => oldQuantityRevenuePerWeek.week
        );
        getNextModay(actualWeek.pop(), idProject);
      }
      setQuantityRevenues(tempQuantityRevenues);
      setIsLoading(false);
      return oldQuantityRevenues;
    } catch (error) {
      console.error('Error fetching QuantityAndRevenue:', error);
    }
  };

  useEffect(() => {
    getProjects(idProject);
    getOldQuantityRevenues(idProject);
  }, [idProject]);
  const [valueFromChild, setValueFromChild] = useState([]);

  const handleChildValueChange = (data, week, id) => {
    const tempWeek = {
      actualWeek: week,
      quantityRevenue: data,
      idQuantityRevenue: id,
    };
    setValueFromChild((prevState) => [
      ...prevState, // Giữ lại tất cả các giá trị hiện có của mảng prevState
      tempWeek, // Thêm tempWeek vào mảng prevState
    ]);
  };
  const addQuantityRevenuePerWeek = () => {
    // debugger;
    // setActualWeek(project?.startDate);
    setQuantityRevenues((oldQuantityRevenuePerWeeks) => {
      return [
        ...oldQuantityRevenuePerWeeks,
        <QuantityRevenuePerWeek
          // data={project?.rpQuantityAndRevenueDetails}
          // startDate={project?.startDate}
          idQuantityRevenue={-Date.now()}
          // week={actualWeek === "" ? project?.startDate : actualWeek.date}
          week={actualWeek.date}
          fromDateToDate={actualWeek.fromDateToDate}
          key={Date.now()}
          onValueChange={handleChildValueChange}
        />,
      ];
    });
    if (actualWeek === '') {
      getNextModay(project?.startDate, idProject);
      return;
    }
    getNextModay(actualWeek.date, idProject);
  };

  const handleSaveQuantityRevenue = async () => {
    // debugger;
    const tempData = [];
    const tempObject = {};
    valueFromChild.forEach((item) => {
      const week = item.actualWeek;
      tempObject[week] = item;
    });

    for (const key in tempObject) {
      tempData.push(tempObject[key]);
    }
    console.log(tempData);
    try {
      const data = await saveActualQuantityAndRevenueAPI(tempData, idProject);
      if (data) {
        setIsLoading(true);
      }
      //sau khi thêm thì lấy lại cái cũ để hiện thị ra giao diện
      await getOldQuantityRevenues(idProject);
      setIsLoading(false);
      toast.success('Cập nhật sản lượng thực tế thành công');
    } catch (error) {
      console.error(error);
      // setIsLoading(false);
      toast.error(error);
    }
  };

  return (
    <div>
      <Toaster position='top-right' />
      {isLoading ? (
        <Loading />
      ) : (
        <Container>
          <div style={{ marginBottom: '15px' }}>
            <NavigationButton
              url={`/projects/${idProject}`}
              name='Quay lại dự án'
            />
          </div>

          <div>
            {quantityRevenues?.map((quantityRevenue, index) => (
              <div key={index}>{quantityRevenue}</div>
            ))}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: '30px',
              marginBottom: '30px',
            }}
          >
            <button
              className='btn btn-warning'
              onClick={addQuantityRevenuePerWeek}
              disabled={errorGetMonday}
            >
              Thêm tuần
            </button>

            <button
              style={{ width: '70px', marginLeft: '30px' }}
              className='btn btn-success'
              onClick={handleSaveQuantityRevenue}
            >
              Lưu
            </button>
          </div>

          <GoToTop />
        </Container>
      )}
    </div>
  );
}
