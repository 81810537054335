import { Divider, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Autocomplete from '@mui/material/Autocomplete';
import ClearIcon from '@mui/icons-material/Clear';
import {
  getCategoriesAPI,
  getCategoriesOneAndTwoAPI,
} from '../../../apis/reportAPI';

export default function ProjectLibraryItem({
  index,
  projectLibraryItems,
  detail = {},
  categoryOneTwo,
  onChange = () => {},
  onRemove = () => {},
}) {
  const [categoryOne, setCategoryOne] = useState([]);
  const [inputValue, setInputValue] = useState(
    detail?.fileName || detail?.linkLibrary
  );
  const [categoryTwo, setCategoryTwo] = useState([]);
  const [linkLibrary, setLinkLibrary] = useState([]);
  // const [selectedCategoryOne, setSelectedCategoryOne] = useState("");
  // const [selectedCategoryTwo, setSelectedCategoryTwo] = useState("");
  const [errorCategoryOne, setErrorCategoryOne] = useState(
    'Vui lòng không bỏ trống'
  );
  const [errorCategoryTwo, setErrorCategoryTwo] = useState(
    'Vui lòng không bỏ trống'
  );
  const [errorLibary, setErrorLibrary] = useState('');
  console.log(detail);
  useEffect(() => {
    if (detail.categoryOne) {
      setErrorCategoryOne('');
    }
    if (detail.categoryTwo) {
      setErrorCategoryTwo('');
    }
    debugger;
    const selectedCategoryOne = categoryOneTwo.filter(
      (category) => category.name === detail.categoryOne
    );
    setCategoryTwo(
      selectedCategoryOne[0] ? selectedCategoryOne[0].categories : []
    );
    setInputValue(detail?.fileName || detail?.linkLibrary);
  }, []);
  console.log(categoryTwo);
  const handleSelectCategoryOne = (id, key, value) => {
    // debugger;
    onChange({
      ...detail,
      categoryOne: value,
      // linkLibrary: "",
    });
    if (value) {
      const selectedCategoryOne = categoryOneTwo.filter(
        (category) => category.name === value
      );
      setCategoryTwo(selectedCategoryOne[0]?.categories);

      for (const item of projectLibraryItems) {
        if (
          value === item.categoryOne &&
          selectedCategoryOne[0]?.categories?.length === 0
        ) {
          return setErrorCategoryOne('Danh mục đã tồn tại');
        }
      }
      setErrorCategoryOne('');
    } else {
      setErrorCategoryOne('Vui lòng không bỏ trống');
    }
  };
  const handleInputChange = (id, key, value) => {
    // debugger;
    if (key === 'categoryTwo') {
      onChange({
        ...detail,
        categoryTwo: value,
      });
      if (value) {
        for (const item of projectLibraryItems) {
          if (value === item.categoryTwo) {
            return setErrorCategoryTwo('Danh mục đã tồn tại');
          }
        }
        setErrorCategoryTwo('');
        // setSelectedCategoryTwo(value);
      } else {
        setErrorCategoryTwo('Vui lòng không bỏ trống');
      }
    } else if (key === 'linkLibrary') {
      setInputValue(value);
      setErrorLibrary('');
      setLinkLibrary(value);
      onChange({
        ...detail,
        linkLibrary: value,
        file: [],
      });
    }
  };

  const handleFileChange = (e, id) => {
    const chosenFiles = [...e.target.files];

    let chosenfileName = chosenFiles.map((file) => file.name);
    // console.log(fileName.join(";"));
    setInputValue(chosenfileName.join(';'));
    onChange({
      ...detail,
      files: chosenFiles,
      fileName: chosenfileName.join(';'),
    });
  };

  const deleteDiv = async (id) => {
    debugger;
    try {
      const result = await Swal.fire({
        title: 'Bạn chắc chắn muốn xóa? ',
        text: 'Hạng mục này sẽ bị xóa vĩnh viễn!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Xóa hạng mục',
        cancelButtonText: 'Hủy bỏ',
      });
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Đã xóa!',
          text: 'Hạng mục đã được xóa thành công.',
          icon: 'success',
        });
        onRemove(detail);
      }
    } catch (error) {}
  };

  return (
    <tr style={{}} index={index}>
      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        {index + 1}
      </td>
      <td>
        <Autocomplete
          size='small'
          sx={{
            display: 'block',
          }}
          value={detail.categoryOne}
          options={categoryOneTwo?.map((option) => option.name)}
          onChange={(e, value) =>
            handleSelectCategoryOne(detail.id, 'categoryOne', value)
          }
          renderInput={(params) => (
            <TextField {...params} placeholder='Danh mục 1' />
          )}
        />
        <span className='text-danger  '>{errorCategoryOne}</span>
      </td>

      <td>
        {(detail.haveCategoryTwo || categoryTwo?.length > 0) && (
          <>
            <Autocomplete
              size='small'
              sx={{
                display: 'block',
              }}
              disablePortal
              value={detail?.categoryTwo}
              options={categoryTwo?.map((option) => option.name)}
              onChange={(e, value) =>
                handleInputChange(detail.id, 'categoryTwo', value)
              }
              renderInput={(params) => (
                <TextField {...params} placeholder='Danh mục 2' />
              )}
            />
            <span className='text-danger '>{errorCategoryTwo}</span>
          </>
        )}
      </td>
      <td>
        <TextField
          sx={{ width: '100%' }}
          placeholder='Nội dung'
          value={inputValue}
          title={inputValue}
          size='small'
          onChange={(e) =>
            handleInputChange(detail.id, 'linkLibrary', e.target.value)
          }
        />
        <span className='text-danger '>{}</span>
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type='file'
            style={{ width: '110px' }}
            className='custom-file-input '
            name='filename'
            multiple
            onChange={(e) => handleFileChange(e, detail.id)}
          />

          <button
            style={{
              width: '25px',
              height: '25px',
              padding: 0,
            }}
            type='button'
            className='btn btn-danger'
            onClick={() => deleteDiv(detail.id)}
          >
            <ClearIcon sx={{ fontSize: '20px', fontWeight: 'bold' }} />
          </button>
        </div>
      </td>
    </tr>
  );
}
