import DownloadIcon from '@mui/icons-material/Download';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Button, Container, Grid, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  fetchPdfEmployee,
  selectEmployeeAPI,
} from '../../../../apis/employeeAPI';
import NavigationButton from '../../../common/NavigationButton';

const EmployeeDetail = () => {
  const navigate = useNavigate();
  const role = Cookies.get('role')?.replace(/"/g, '');
  // READ
  const getPdfDetail = async (type, path, id) => {
    try {
      const url = await fetchPdfEmployee(type, path, id);
      window.open(url, '_blank');
    } catch (error) {}
  };

  const params = useParams();
  const [employee, setEmployee] = useState();

  const idEmployee = params.code;
  const getEmployeeByID = async (idEmployee) => {
    try {
      const data = await selectEmployeeAPI(idEmployee);
      setEmployee(data);

      return data;
    } catch (error) {
      console.error('Error fetching Employees:', error);
    }
  };
  useEffect(() => {
    getEmployeeByID(idEmployee);
  }, [idEmployee]);
  if (!employee) {
    return;
  }
  //download IMAGE
  const downloadQRImage = async () => {
    try {
      const response = await fetch(employee.imageOfQR);
      const blob = await response.blob();

      // Lấy loại dữ liệu từ phản hồi để xác định định dạng của ảnh
      const contentType = response.headers.get('content-type');

      // Tạo một URL đến dữ liệu nhận được
      const url = window.URL.createObjectURL(
        new Blob([blob], { type: contentType })
      );

      // Tạo một thẻ <a> để tải xuống
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', employee.code); // Đổi định dạng ảnh thành định dạng bạn muốn tải xuống
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  return (
    <div>
      <Container className='mt-4 mb-4'>
        <div className='mb-2'>
          <NavigationButton
            url='/catalogue/employees'
            name='Danh sách nhân sự'
          />
        </div>
        <Grid container spacing={5} style={{ overflow: 'hidden' }}>
          <Grid item xs={12} lg={6}>
            <Typography
              sx={{ fontWeight: 'bold', marginBottom: '15px' }}
              variant='h5'
              gutterBottom
            >
              I. THÔNG TIN CHUNG
            </Typography>

            <table class='table table-bordered'>
              <tbody>
                <tr>
                  <td
                    style={{
                      width: '150px',
                      fontWeight: 'bold',
                    }}
                  >
                    Tên nhân sự
                  </td>
                  <td>{employee.name}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '150px',
                      fontWeight: 'bold',
                    }}
                  >
                    Mã nhân sự
                  </td>
                  <td>{employee.code}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '150px',
                      fontWeight: 'bold',
                    }}
                  >
                    Phòng ban
                  </td>
                  <td>{employee.department}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '150px',
                      fontWeight: 'bold',
                    }}
                  >
                    Chức vụ
                  </td>
                  <td>{employee.position}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '150px',
                      fontWeight: 'bold',
                    }}
                  >
                    Hình thức
                  </td>
                  <td>{employee.method}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '150px',
                      fontWeight: 'bold',
                    }}
                  >
                    Địa chỉ
                  </td>
                  <td
                    style={{
                      maxWidth: '350px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {employee.contact}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography
              sx={{ fontWeight: 'bold', marginBottom: '15px' }}
              variant='h5'
              gutterBottom
            >
              II. MÃ QR
            </Typography>
            <div>
              <img
                style={{ width: '200px', border: '1px solid' }}
                src={employee.imageOfQR}
                alt={employee.code}
                id='qrImage'
              />
            </div>
            <div style={{ marginLeft: '55px', marginTop: '5px' }}>
              <Button sx={{ fontSize: '16px' }} onClick={downloadQRImage}>
                <span style={{ color: 'black', marginRight: '4px' }}>
                  {' '}
                  {employee.code}{' '}
                </span>
                <DownloadForOfflineIcon />
              </Button>
            </div>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography
              sx={{ fontWeight: 'bold', marginBottom: '15px' }}
              variant='h5'
              gutterBottom
            >
              III. HỒ SƠ NĂNG LỰC
            </Typography>
            <table class='table table-bordered'>
              <tbody>
                {/* {employee?.nameFileID && (
                  <tr>
                    <td
                      style={{
                        width: '200px',
                        fontWeight: 'bold',
                      }}
                    >
                      Căn cước công dân
                    </td>
                    <td>
                      {employee?.nameFileID &&
                        employee?.nameFileID !== 'undefined' && (
                          <span
                            title={employee?.nameFileID}
                            className='text-primary'
                            style={{
                              cursor: 'pointer',
                              whiteSpace: 'nowrap',
                            }}
                            onClick={() =>
                              getPdfDetail(
                                'attachment',
                                // employee?.nameFileID,
                                'PATHID',
                                employee?.id
                              )
                            }
                          >
                            <DownloadIcon />
                            {employee?.nameFileID}
                          </span>
                        )}
                    </td>
                  </tr>
                )} */}
                {employee?.nameFileDegree && (
                  <tr>
                    <td
                      style={{
                        width: '200px',
                        fontWeight: 'bold',
                      }}
                    >
                      Bằng cấp - chứng chỉ
                    </td>
                    <td>
                      {employee?.nameFileDegree &&
                        employee?.nameFileDegree !== 'undefined' && (
                          <span
                            title={employee?.nameFileDegree}
                            className='text-primary'
                            style={{
                              cursor: 'pointer',
                              whiteSpace: 'nowrap',
                            }}
                            onClick={() =>
                              getPdfDetail(
                                'attachment',
                                'PATHDEGREE',
                                employee?.id
                              )
                            }
                          >
                            <DownloadIcon />
                            {employee?.nameFileDegree}
                          </span>
                        )}
                    </td>
                  </tr>
                )}
                {employee?.nameFileSafetyCard && (
                  <tr>
                    <td
                      style={{
                        width: '200px',
                        fontWeight: 'bold',
                      }}
                    >
                      Thẻ an toàn lao động
                    </td>
                    <td>
                      {employee?.nameFileSafetyCard &&
                        employee?.nameFileSafetyCard !== 'undefined' && (
                          <span
                            title={employee?.nameFileSafetyCard}
                            className='text-primary'
                            style={{
                              cursor: 'pointer',
                              whiteSpace: 'nowrap',
                            }}
                            onClick={() =>
                              getPdfDetail(
                                'attachment',
                                'PATHSAFETYCARD',
                                employee?.id
                              )
                            }
                          >
                            <DownloadIcon />
                            {employee?.nameFileSafetyCard}
                          </span>
                        )}
                    </td>
                  </tr>
                )}
                {/* {employee?.nameFileContract && (
                  <tr>
                    <td
                      style={{
                        width: '200px',
                        fontWeight: 'bold',
                      }}
                    >
                      Hợp đồng lao động
                    </td>
                    <td>
                      {employee?.nameFileContract &&
                        employee?.nameFileContract !== 'undefined' && (
                          <span
                            title={employee?.nameFileContract}
                            className='text-primary'
                            style={{
                              cursor: 'pointer',
                              whiteSpace: 'nowrap',
                            }}
                            onClick={() =>
                              getPdfDetail(
                                'attachment',
                                'PATHCONTRACT',
                                employee?.id
                              )
                            }
                          >
                            <DownloadIcon />
                            {employee?.nameFileContract}
                          </span>
                        )}
                    </td>
                  </tr>
                )} */}
                {employee?.nameFileHealthCer && (
                  <tr>
                    <td
                      style={{
                        width: '200px',
                        fontWeight: 'bold',
                      }}
                    >
                      Giấy khám sức khỏe
                    </td>
                    <td>
                      {employee?.nameFileHealthCer &&
                        employee?.nameFileHealthCer !== 'undefined' && (
                          <span
                            title={employee?.nameFileHealthCer}
                            className='text-primary'
                            style={{
                              cursor: 'pointer',
                              whiteSpace: 'nowrap',
                            }}
                            onClick={() =>
                              getPdfDetail(
                                'attachment',
                                'PATHHEALTHCER',
                                employee?.id
                              )
                            }
                          >
                            <DownloadIcon />
                            {employee?.nameFileHealthCer}
                          </span>
                        )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default EmployeeDetail;
