import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import { getCostsAPI } from '../../../apis/reportAPI';
import { useParams } from 'react-router-dom';
import { Box, Grid, TextField } from '@mui/material';
import ActualCostItem from './ActualCostItem';
import DateRangeIcon from '@mui/icons-material/DateRange';

export default function ActualCostPerWeek({
  idActualCost,
  week,
  fromDateToDate,
  actualCostDetails,
  onValueChange = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const newEmptyActualCostDetail = () => {
    return {
      id: -Date.now(),
      cost: '',
      amount: '',
    };
  };
  const params = useParams();
  const [disableAddItem, setDisableAddItem] = useState(false);
  const [countItem, setCountItem] = useState(0);
  const [remainingCosts, setRemainingCosts] = useState([]);

  const idProject = params.code;

  const [actualCostItems, setActualCostItems] = useState(
    actualCostDetails ? actualCostDetails : []
  );
  const addActualCostItem = () => {
    const tempCount = countItem + 1;
    setCountItem(tempCount);
    if (tempCount === costs.length) {
      setDisableAddItem(true);
    }
    setActualCostItems((oldActualCostItems) => {
      return [...oldActualCostItems, newEmptyActualCostDetail()];
    });
  };
  //Tính tổng tiền
  const [totalAmount, setTotalAmount] = useState(0);

  const updateTotalAmount = () => {
    const totalAmountNew = actualCostItems.reduce(
      (accumulator, actualCostItem) => {
        return accumulator + actualCostItem.amount * 1;
      },
      0
    );
    setTotalAmount(totalAmountNew);
  };
  // Get cost selection
  const [costs, setCosts] = useState([]);
  useEffect(() => {
    // debugger;
    async function fetchCostsAPI() {
      let costs = await getCostsAPI();
      setCosts(costs);
      let remaining = [];
      costs.forEach((item2) => {
        if (!actualCostDetails?.some((item1) => item1.cost === item2.name)) {
          remaining.push({
            name: item2.name,
          });
        }
      });
      setRemainingCosts(remaining);
      setCountItem(costs?.length - remaining.length);
      if (remaining.length === 0) {
        setDisableAddItem(true);
      }
    }
    fetchCostsAPI();
  }, []);
  const handleActualCostDetailChange = (detail) => {
    setActualCostItems((oldActualCostItems) => {
      const index = oldActualCostItems.findIndex((el) => el.id === detail.id);
      const newActualCostItems = [...oldActualCostItems]; // clone array, avoid side effect
      newActualCostItems.splice(index, 1, detail);
      return [...newActualCostItems];
    });
  };

  const handleRemoveActualCostDetail = (detail) => {
    const tempCount = countItem - 1;
    setCountItem(tempCount);

    if (tempCount < costs.length) {
      setDisableAddItem(false);
    }
    const filteredCosts = detail;
    if (filteredCosts.cost) {
      let obj3 = [];
      costs.forEach((item2) => {
        if (filteredCosts.cost === item2.name) {
          obj3.push({
            name: item2.name,
          });
        }
        const newcosts = [...remainingCosts, obj3[0]];
        setRemainingCosts([...remainingCosts, obj3[0]]);
        if (newcosts.length > 0) {
          setDisableAddItem(false);
        }
      });
    }
    setActualCostItems((oldActualCostItems) => {
      return [...oldActualCostItems.filter((el) => detail.id !== el.id)];
    });
  };
  const handleCostSelect = (selectedCost) => {
    const temCostIndex = remainingCosts?.findIndex(
      (el) => el.name === selectedCost?.name
    );
    if (temCostIndex !== -1) {
      const updatedremainingCosts = [...remainingCosts];
      updatedremainingCosts.splice(temCostIndex, 1);
      setRemainingCosts(updatedremainingCosts);
      return selectedCost;
    } else if (remainingCosts.length === 1) {
      setDisableAddItem(true);
      return;
    }
  };

  useEffect(() => {
    onValueChange(actualCostItems, week, idActualCost);
    updateTotalAmount();
  }, [actualCostItems, idActualCost]);

  return (
    <div style={{ marginBottom: '20px' }}>
      {idActualCost > 0 ? (
        <Box maxWidth={'md'} sx={{ marginLeft: '150px' }}>
          <span
            colSpan={6}
            className="mb-2 fw-bold d-inline-block text-primary"
            style={{ fontSize: '15px', borderBottom: '3px solid' }}
          >
            {' '}
            <DateRangeIcon className="mb-1" /> {fromDateToDate}
          </span>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    width: '45px',
                  }}
                  className="text-center fw-bold"
                >
                  STT
                </th>
                <th style={{ width: '540px' }} className=" fw-bold">
                  Hạng mục<span className="text-danger">*</span>
                </th>

                <th style={{ width: '315px' }} className=" fw-bold">
                  Thành tiền<span className="text-danger">*</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {actualCostItems.map((detail, index) => (
                <ActualCostItem
                  index={index}
                  key={detail.id}
                  detail={detail}
                  costs={costs}
                  remainingCosts={[
                    ...remainingCosts,
                    ...costs.filter((el) => el.name === detail.cost),
                  ]}
                  onCostSelect={handleCostSelect}
                  onChange={handleActualCostDetailChange}
                  onRemove={handleRemoveActualCostDetail}
                  updateTotalAmount={updateTotalAmount}
                />
              ))}
              <tr>
                <td colSpan={3} style={{ textAlign: 'end' }}>
                  <div className="d-flex align-items-end justify-content-between me-5">
                    <button
                      className="btn btn-dark"
                      disabled={disableAddItem}
                      onClick={addActualCostItem}
                    >
                      Thêm hạng mục
                    </button>
                    <span className=" fw-bold" style={{ fontSize: '15px' }}>
                      Tổng cộng: {`${totalAmount.toLocaleString()}`}{' '}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      ) : (
        <Box maxWidth={'md'} sx={{ marginLeft: '150px' }}>
          <span
            colSpan={6}
            className="mb-2 fw-bold d-inline-block text-primary"
            style={{ fontSize: '15px', borderBottom: '3px solid' }}
          >
            {' '}
            <DateRangeIcon className="mb-1" /> {fromDateToDate}
          </span>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    width: '45px',
                  }}
                  className="text-center fw-bold"
                >
                  STT
                </th>
                <th style={{ width: '540px' }} className=" fw-bold">
                  Hạng mục<span className="text-danger">*</span>
                </th>

                <th style={{ width: '315px' }} className=" fw-bold">
                  Thành tiền<span className="text-danger">*</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {actualCostItems.map((detail, index) => (
                <ActualCostItem
                  index={index}
                  key={detail.id}
                  detail={detail}
                  costs={costs}
                  remainingCosts={[
                    ...remainingCosts,
                    ...costs.filter((el) => el.name === detail.cost),
                  ]}
                  onCostSelect={handleCostSelect}
                  onChange={handleActualCostDetailChange}
                  onRemove={handleRemoveActualCostDetail}
                  updateTotalAmount={updateTotalAmount}
                />
              ))}
              <tr>
                <td colSpan={6} style={{ textAlign: 'end' }}>
                  <div className="d-flex align-items-end justify-content-between me-5">
                    <button
                      className="btn btn-dark"
                      disabled={disableAddItem}
                      onClick={addActualCostItem}
                    >
                      Thêm hạng mục
                    </button>
                    <span className=" fw-bold" style={{ fontSize: '15px' }}>
                      Tổng cộng: {`${totalAmount.toLocaleString()}`}{' '}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      )}
    </div>
  );
}
