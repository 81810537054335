import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import ClearIcon from '@mui/icons-material/Clear';

export default function QuantityRevenueItem({
  index,
  detail,
  categories,
  remainingCategories,
  updateTotalAmount = () => {},
  onChange = () => {},
  onRemove = () => {},
  onCategorySelect,
}) {
  // Hàm để tính tổng tiền
  const [quantity, setQuantity] = useState(
    detail?.quantity ? detail?.quantity : ''
  );
  const [price, setPrice] = useState(detail?.price || '');
  const [amount, setAmount] = useState(detail?.amount || quantity * price);
  const [error, setError] = useState('Vui lòng không bỏ trống');

  useEffect(() => {
    if (detail.category && detail.quantity) {
      setError('');
    } else {
      setError('Vui lòng không bỏ trống');
    }
  }, [detail]);
  useEffect(() => {
    updateTotalAmount();
  }, [amount]);
  const handleInputChange = (id, key, value) => {
    // debugger;
    if (key === 'quantity') {
      setQuantity(value);
      setAmount(value * price);
    } else if (key === 'price') {
      setPrice(value);
      setAmount(value * quantity);
    }
    onChange({
      ...detail,
      [key]: value,
    });
  };

  const [unit, setUnit] = useState(detail?.unit ? detail?.unit : '');
  const handleSelectCategory = async (event, value) => {
    // debugger;
    const category = value;
    const selectedCategory = categories.find((el) => el.name === category);

    const validateCategory = onCategorySelect(selectedCategory);
    if (validateCategory) {
      setUnit(selectedCategory.unit);
      setPrice(selectedCategory.price);
      onChange({
        ...detail,
        unit: selectedCategory.unit,
        price: selectedCategory.price,
        category,
      });
    }
  };
  //xóa item
  const deleteDiv = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Bạn chắc chắn muốn xóa? ',
        text: 'Hạng mục này sẽ bị xóa vĩnh viễn!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Xóa hạng mục',
        cancelButtonText: 'Hủy bỏ',
      });
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Đã xóa!',
          text: 'Hạng mục đã được xóa thành công.',
          icon: 'success',
        });
        onRemove(detail);
      }
    } catch (error) {}
  };

  return (
    <>
      <tr>
        <td
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}
        >
          {index + 1}
        </td>
        <td>
          <Autocomplete
            size='small'
            disablePortal
            id='combo-box-demo'
            options={remainingCategories?.map((option) => option.name)}
            defaultValue={detail?.category}
            disabled={detail?.category ? true : false}
            onChange={handleSelectCategory}
            renderInput={(params) => (
              <TextField {...params} placeholder='Tên hạng mục' />
            )}
          />
          <span className='text-danger'>{error}</span>
        </td>
        <td>
          <TextField
            placeholder='Đơn vị'
            id='outlined-size-small'
            value={unit}
            size='small'
            disabled={true}
          />
        </td>
        <td>
          <TextField
            // placeholder='Sản lượng'
            id='outlined-size-small'
            value={quantity}
            size='small'
            type='number'
            onChange={(e) =>
              handleInputChange(detail.id, 'quantity', e.target.value)
            }
          />
        </td>
        <td>
          <TextField
            placeholder='Đơn giá(VND)'
            id='outlined-size-small'
            value={price.toLocaleString()}
            size='small'
            // type="number"
            disabled={true}
            onChange={(e) =>
              handleInputChange(detail.id, 'price', e.target.value)
            }
          />
        </td>
        <td>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              placeholder='Thành tiền(VND)'
              id='outlined-size-small'
              value={`${amount.toLocaleString()}`}
              size='small'
              disabled={true}
            />
            <button
              className='btn btn-danger ms-2'
              style={{
                width: '32px',
                height: '32px',
                padding: '0',
              }}
              onClick={() => deleteDiv(detail.id)}
              type='button'
            >
              <ClearIcon sx={{ fontSize: '20px', fontWeight: 'bold' }} />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
}
